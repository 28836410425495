<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column">
                    <div class="card bg-light d-flex flex-fill">
                        <div class="card-header text-muted border-bottom-0">
                        {{siswa.nama_siswa}}
                        </div>
                        <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-12">
                            <h5 class="text-bold">{{siswa.kelas}}</h5></div>
                        </div>
                        </div>
                        <div class="card-footer">
                        <div class="text-right">                            
                            <router-link :to="'/diskusi-kelas/'" class="btn btn-sm btn-secondary">
                            <i class="fas fa-arrow-left"></i> Ke Diskusi Kelas</router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-danger direct-chat direct-chat-primary">
                    <div class="card-header">
                        <h3 class="card-title">Direct Chat</h3>
                        <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-widget="collapse">
                            <i class="fas fa-minus"></i>
                        </button>
                        </div>
                    </div>
            <!-- /.card-header -->
                    <div class="card-body">
                        <!-- Conversations are loaded here -->
                    <div class="direct-chat-messages">
                    <!-- Message. Default to the left -->
                        <div v-for="row in optChat" v-bind:key="row.id"
                        v-bind:value="row.id">
                        <div class="direct-chat-msg" v-if="row.type == 'bot'">
                            <div class="direct-chat-infos clearfix">
                            <span class="direct-chat-name float-left">Taruna Admin</span>
                            <span class="direct-chat-timestamp float-right">{{row.created_by}}</span>
                            </div>
                            <!-- /.direct-chat-infos -->
                            <img class="direct-chat-img" width="10px" src="https://img.icons8.com/external-becris-lineal-becris/64/000000/external-user-mintab-for-ios-becris-lineal-becris.png"/>
                            <!-- /.direct-chat-img -->
                            <div class="direct-chat-text">{{row.content}}</div>
                            <!-- /.direct-chat-text -->
                        </div>

                        <div class="direct-chat-msg right mb-1" v-if="row.type == 'user'">
                            <div class="direct-chat-infos clearfix">
                            <span class="direct-chat-name float-right">{{row.siswa}}</span>
                            <span class="direct-chat-timestamp float-left">{{row.created_by}}</span>
                            </div>
                            <!-- /.direct-chat-infos -->
                            <img class="direct-chat-img" width="10px" src="https://img.icons8.com/external-becris-lineal-becris/64/000000/external-user-mintab-for-ios-becris-lineal-becris.png"/>
                            <!-- /.direct-chat-img -->
                            <div class="direct-chat-text" v-if="row.type_chat == 'text'">{{row.content}}</div>
                            <div class="direct-chat-text" v-if="row.type_chat == 'img'"><img :src="url+'/file/image/'+row.img_code" :alt="row.img_code" id="imgPreview" @click="popupImg(row.img_code)" width="50%;"></div>
                            <!-- /.direct-chat-text -->
                        </div>
                        <!-- /.direct-chat-msg -->
                        </div>
                    </div>
                        <!--/.direct-chat-messages-->
                    </div>
                    <!-- /.card-body -->
                    <div class="card-footer">
                    </div>
                    <!-- /.card-footer-->
                    </div>
                </div>
            </div>
        </div>
    <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static" >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <form role="form">
                <div class="modal-header">
                <h5 class="modal-title">Preview Gambar</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                <div class="row">
                <div v-for="row in imgPreview" v-bind:key="row.img_code">
                    <img :src="url+'/file/image/'+row.img_code" :alt="row.img_code" width="100%;">
                </div>
                </div>
                </div>
                <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Tutup
                </button>
                </div>
            </form>
            </div>
        </div>
    </div>
    </section>
</template>
<script>
import { auth, authFetch } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";

    export default {
    name: 'Tanya',
    data: () => ({
        guru: {},
        filter: {},
        optChat: [],
        form: {
            thread_id: '',
            content: '',
            img_code: '',
        },
        siswa: {
            nama_siswa: '',
            kelas: '',
        },
        url: '',
        roles: "",
        form_data: "",
        userid: '',
        imgPreview: [],
    }),
    created: function () {
        if (auth.user()) {
            this.userid = auth.user().id;
        }

        if(this.$route.params.filter) {
        this.filter = this.$route.params.filter;
        }
        
        if(Object.keys(this.filter).length<1)
        {
        if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
        }
        else{
        sessionStorage.filterData = JSON.stringify(this.filter);
        sessionStorage.filterStatus = 1;
        }

        this.siswa.nama_siswa = this.filter.nama_siswa;
        this.siswa.kelas = this.filter.kelas;

        this.loadChat(this.filter);
        this.loadThread(this.filter);

        this.url = window.$apiUrl;
    },
    methods: {
        popupImg(img_code){
            const e = this.$refs;
            let data = this.optChat;
            let img = data.filter(function(arr, i) {
                return (
                    arr.img_code == img_code
                );
            });
            this.imgPreview = img;
            $(e.formDialog).modal("show");
        },
        loadThread(params){
            let data = '';
            data = Object.keys(params)
            .map(
            (key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            )
            .join("&");
            authFetch("/tentor/tanya_guru/chat_id?"+data).then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        this.form.thread_id = json.data.id;
                        // console.log(this.form.thread_id);
                    }
                });
            });
        },
        loadChat(params){
            let data = '';
            data = Object.keys(params)
            .map(
            (key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            )
            .join("&");
            authFetch("/tentor/tanya_guru/chat?"+data).then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        this.optChat = json.data;
                    }
                });
            });
        },
    },
    mounted(){}
    }
</script>
<style type="text/css" scoped>
</style>